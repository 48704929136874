import { OptionTypes, NodeType } from './options'; // Adjust the path based on your project structure

/**
 * This input dialog is for the
 * 1- creation of nodes
 * 2- modification of nodes
 *
 * @param {*} onConfirm  onConfirm callback
 * @param {*} defaults default parameters sent to this method
 */
export function createInputDialog(onConfirm, defaults = {}) {
    console.log("createInputDialog");
    const { nodeName = '', inputs = [], outputs = [], selectedType = NodeType.FUNCTION } = defaults;

    const dialog = document.createElement('div');
    dialog.style.position = 'fixed';
    dialog.style.top = '50%';
    dialog.style.left = '50%';
    dialog.style.transform = 'translate(-50%, -50%)';
    dialog.style.backgroundColor = 'white';
    dialog.style.padding = '20px';
    dialog.style.width = '500px';
    dialog.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
    dialog.style.zIndex = '1001';
    dialog.style.borderRadius = '10px';
    dialog.style.fontFamily = 'Arial, sans-serif';

    const title = document.createElement('h3');
    title.textContent = 'Configure Node';
    title.style.marginBottom = '15px';
    title.style.textAlign = 'center';
    dialog.appendChild(title);

    // Close button
    const closeButton = document.createElement('button');
    closeButton.textContent = 'X';
    closeButton.style.position = 'absolute';
    closeButton.style.top = '10px';
    closeButton.style.right = '10px';
    closeButton.style.backgroundColor = 'transparent';
    closeButton.style.border = 'none';
    closeButton.style.color = '#aaa';
    closeButton.style.fontSize = '16px';
    closeButton.style.cursor = 'pointer';
    closeButton.onclick = () => document.body.removeChild(dialog);
    dialog.appendChild(closeButton);

    // Node name input
    const nameContainer = document.createElement('div');
    nameContainer.style.marginBottom = '15px';

    const nameLabel = document.createElement('label');
    nameLabel.textContent = 'Node Name:';
    nameLabel.style.fontWeight = 'bold';
    nameContainer.appendChild(nameLabel);

    const nameInput = document.createElement('input');
    nameInput.type = 'text';
    nameInput.placeholder = 'Enter node name';
    nameInput.style.marginTop = '5px';
    nameInput.style.width = '100%';
    nameInput.style.padding = '8px';
    nameInput.style.border = '1px solid #ccc';
    nameInput.style.borderRadius = '4px';
    nameInput.value = nodeName;
    nameContainer.appendChild(nameInput);
    dialog.appendChild(nameContainer);

    const inputList = [];
    const outputList = [];

    // Create a container for input fields
    const inputsContainer = document.createElement('div');
    inputsContainer.style.marginBottom = '15px';

    const inputsLabel = document.createElement('label');
    inputsLabel.textContent = 'Inputs:';
    inputsLabel.style.fontWeight = 'bold';
    inputsContainer.appendChild(inputsLabel);

    // Function to add input fields with a remove button
    const addInputField = (container, list, defaultValue = '') => {
        const fieldContainer = document.createElement('div');
        fieldContainer.style.display = 'flex';
        fieldContainer.style.alignItems = 'center';
        fieldContainer.style.marginTop = '5px';

        const inputField = document.createElement('input');
        inputField.type = 'text';
        inputField.placeholder = 'Input ID';
        inputField.style.flex = '1';
        inputField.style.padding = '8px';
        inputField.style.border = '1px solid #ccc';
        inputField.style.borderRadius = '4px';
        inputField.value = defaultValue;
        fieldContainer.appendChild(inputField);

        const removeButton = document.createElement('button');
        removeButton.textContent = '🗑️';
        removeButton.style.marginLeft = '10px';
        removeButton.style.backgroundColor = 'transparent';
        removeButton.style.border = 'none';
        removeButton.style.cursor = 'pointer';
        removeButton.onclick = () => {
            container.removeChild(fieldContainer);
            const index = list.indexOf(inputField);
            if (index > -1) list.splice(index, 1);
        };
        fieldContainer.appendChild(removeButton);

        container.appendChild(fieldContainer);
        list.push(inputField);
    };

    // Add default input fields if provided
    inputs.forEach(input => addInputField(inputsContainer, inputList, input));

    // Add Input button
    const addInputButton = document.createElement('button');
    addInputButton.textContent = 'Add Input';
    addInputButton.style.marginTop = '10px';
    addInputButton.style.padding = '8px';
    addInputButton.style.width = '100%';
    addInputButton.style.backgroundColor = '#007BFF';
    addInputButton.style.color = 'white';
    addInputButton.style.border = 'none';
    addInputButton.style.borderRadius = '5px';
    addInputButton.style.cursor = 'pointer';
    addInputButton.onclick = () => addInputField(inputsContainer, inputList);
    inputsContainer.appendChild(addInputButton);
    dialog.appendChild(inputsContainer);

    // Create a container for output fields
    const outputsContainer = document.createElement('div');
    outputsContainer.style.marginBottom = '15px';

    const outputsLabel = document.createElement('label');
    outputsLabel.textContent = 'Outputs:';
    outputsLabel.style.fontWeight = 'bold';
    outputsContainer.appendChild(outputsLabel);

    // Function to add output fields with a remove button
    const addOutputField = (container, list, defaultOutput = {}) => {
        const fieldContainer = document.createElement('div');
        fieldContainer.style.display = 'flex';
        fieldContainer.style.alignItems = 'center';
        fieldContainer.style.marginTop = '5px';

        const outputID = document.createElement('input');
        outputID.type = 'text';
        outputID.placeholder = 'Output ID';
        outputID.style.flex = '1';
        outputID.style.padding = '8px';
        outputID.style.border = '1px solid #ccc';
        outputID.style.borderRadius = '4px';
        outputID.value = defaultOutput.id || '';
        fieldContainer.appendChild(outputID);

        const comparisonType = document.createElement('select');
        comparisonType.style.flex = '1';
        comparisonType.style.marginLeft = '10px';
        comparisonType.style.padding = '8px';
        comparisonType.style.border = '1px solid #ccc';
        comparisonType.style.borderRadius = '4px';
        Object.values(OptionTypes).forEach(optionText => {
            const option = document.createElement('option');
            option.value = optionText;
            option.textContent = optionText;
            comparisonType.appendChild(option);
        });
        comparisonType.value = defaultOutput.comparisonType || OptionTypes.EQUALS;
        fieldContainer.appendChild(comparisonType);

        const removeButton = document.createElement('button');
        removeButton.textContent = '🗑️';
        removeButton.style.marginLeft = '10px';
        removeButton.style.backgroundColor = 'transparent';
        removeButton.style.border = 'none';
        removeButton.style.cursor = 'pointer';
        removeButton.onclick = () => {
            container.removeChild(fieldContainer);
            const index = list.findIndex(item => item.outputID === outputID);
            if (index > -1) list.splice(index, 1);
        };
        fieldContainer.appendChild(removeButton);

        container.appendChild(fieldContainer);
        list.push({ outputID, comparisonType });
    };

    // Add default output fields if provided
    outputs.forEach(output => addOutputField(outputsContainer, outputList, output));

    // Add Output button
    const addOutputButton = document.createElement('button');
    addOutputButton.textContent = 'Add Output';
    addOutputButton.style.marginTop = '10px';
    addOutputButton.style.padding = '8px';
    addOutputButton.style.width = '100%';
    addOutputButton.style.backgroundColor = '#28a745';
    addOutputButton.style.color = 'white';
    addOutputButton.style.border = 'none';
    addOutputButton.style.borderRadius = '5px';
    addOutputButton.style.cursor = 'pointer';
    addOutputButton.onclick = () => addOutputField(outputsContainer, outputList);
    outputsContainer.appendChild(addOutputButton);
    dialog.appendChild(outputsContainer);

    // Add node type selector
    const nodeTypeContainer = document.createElement('div');
    nodeTypeContainer.style.marginBottom = '15px';

    const nodeTypeLabel = document.createElement('label');
    nodeTypeLabel.textContent = 'Node Type:';
    nodeTypeLabel.style.fontWeight = 'bold';
    nodeTypeContainer.appendChild(nodeTypeLabel);

    const nodeTypeSelector = document.createElement('select');
    nodeTypeSelector.style.marginTop = '5px';
    nodeTypeSelector.style.width = '100%';
    nodeTypeSelector.style.padding = '8px';
    nodeTypeSelector.style.border = '1px solid #ccc';
    nodeTypeSelector.style.borderRadius = '4px';
    Object.values(NodeType).forEach(nodeType => {
        const option = document.createElement('option');
        option.value = nodeType;
        option.textContent = nodeType;
        nodeTypeSelector.appendChild(option);
    });
    nodeTypeSelector.value = selectedType;
    nodeTypeContainer.appendChild(nodeTypeSelector);
    dialog.appendChild(nodeTypeContainer);

    // Confirm button
    const confirmButton = document.createElement('button');
    confirmButton.textContent = 'Confirm';
    confirmButton.style.marginTop = '20px';
    confirmButton.style.padding = '10px';
    confirmButton.style.width = '100%';
    confirmButton.style.backgroundColor = '#28a745';
    confirmButton.style.color = 'white';
    confirmButton.style.border = 'none';
    confirmButton.style.borderRadius = '5px';
    confirmButton.style.cursor = 'pointer';
    confirmButton.onclick = () => {
        const nodeName = nameInput.value.trim();
        const inputs = inputList.map(inputField => inputField.value.trim());
        const outputs = outputList.map(output => ({
            id: output.outputID.value.trim(),
            comparisonType: output.comparisonType.value
        }));
        const selectedNodeType = nodeTypeSelector.value;

        if (!nodeName) {
            alert('Node name cannot be empty.');
            return;
        }

        if (inputs.some(id => id.includes(' ')) || outputs.some(output => output.id.includes(' '))) {
            alert('IDs cannot contain spaces.');
            return;
        }

        onConfirm(nodeName, inputs, outputs, selectedNodeType);
        document.body.removeChild(dialog);
    };

    dialog.appendChild(confirmButton);
    document.body.appendChild(dialog);
}

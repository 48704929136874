// exportLogic.js
import { AreaPlugin, AreaExtensions } from "rete-area-plugin";
import { CustomNode } from "../nodes/CustomNodes"; // Importing CustomNode and CustomConnection
import { socket } from '../sockets/sockets'; // Adjust the import path accordingly
import { GetSchemes, ClassicPreset, NodeEditor } from "rete";
import { CustomConnection } from "../nodes/CustomNodes"; // Importing CustomNode and CustomConnection
import { saveData } from '../components/TopBar/TopBarService'; // Adjust the path based on your project structure
// importLogic.js
import { ButtonControl, ProgressControl, CustomButton, CustomProgress } from '../components/CustomControls';

import globalStoreC from '../architecture/globalStore'; // Import globalStoreC


function generateJSON(editor, area) {
  const data = { nodes: [], connections: [] };
  const nodes = editor.getNodes();

  // Export nodes
  for (const node of nodes) {
    const nodeView = area.nodeViews.get(node.id); // Get the node view for the current node
    const position = nodeView ? nodeView.position : { x: 0, y: 0 }; // Default to (0, 0) if not found

    // Serialize node inputs
    const inputsEntries = Object.entries(node.inputs || {}).map(([key, input]) => {
      return [key, {
        id: input.id,
        label: input.label,
        socket: {
          name: input.socket.name
        }
      }];
    });

    // Serialize node outputs
    const outputsEntries = Object.entries(node.outputs || {}).map(([key, output]) => {
      return [key, {
        id: output.id,
        label: output.label,
        socket: {
          name: output.socket.name
        }
      }];
    });

    // Serialize controls
    const controlsEntries = Object.entries(node.controls || {}).map(([key, control]) => {
      return [key, {
        id: control.id,
        value: control.getData(),
        type: control.constructor.name
      }];
    });

        // Retrieve global store data for the current node ID
        const globalData = globalStoreC.getDataById(node.id) || {}; // Get the data from globalStoreC or an empty object if not found


    // Node data structure
    const nodeData = {
      id: node.id,
      name: node.name,
      position: position,
      data: node.data,
      inputs: Object.fromEntries(inputsEntries),
      outputs: Object.fromEntries(outputsEntries),
      controls: Object.fromEntries(controlsEntries),
      selectedType: node.selectedType,
      codeData: globalData
    };


    data.nodes.push(nodeData);
  }

  // Export connections
  editor.connections.forEach(connection => {
    data.connections.push({
      id: connection.id,
      source: {
        node: connection.source,
        output: connection.sourceOutput
      },
      target: {
        node: connection.target,
        input: connection.targetInput
      }
    });
  });

  return data;
}


function removeCircularReferences() {
  const seen = new WeakSet();
  return function (key, value) {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return; // Remove circular references
      }
      seen.add(value);
    }
    return value;
  };
}

export function exportEditorContent(editor, area) {

  const data = generateJSON(editor, area);

  // Use the custom replacer function to handle circular references
  const jsonString = JSON.stringify(data, removeCircularReferences(), 2);

  // Create a Blob from the JSON string
  const blob = new Blob([jsonString], { type: 'application/json' });

  // Create a download link
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'editor-content.json';
  a.click();

  // Clean up the URL object
  URL.revokeObjectURL(url);
}



export function saveEditorContent(editor, area, id) {

  const data = generateJSON(editor, area);
  saveData(id,[JSON.stringify(data, null, 2)]);
  
}


export async function importEditorContent(data, editor, area) {
  console.log("importEditorContent")
  // Clear the editor before importing new data
  editor.clear();

  const nodesMap = new Map();
  const addNodeTasks = [];

  // Step 1: Reconstruct Nodes
  for (const { id, label, inputs, outputs, controls, position, selectedType, codeData } of data.nodes) {
    const node = new CustomNode(label, area, [], [], selectedType);
    node.id = id;

    // Reconstruct inputs
    Object.entries(inputs).forEach(([key, input]) => {
      const socket = new ClassicPreset.Socket(input.socket.name);
      const inp = new ClassicPreset.Input(socket, input.label);
      inp.id = input.id;
      node.addInput(key, inp);
    });

    // Reconstruct outputs
    Object.entries(outputs).forEach(([key, output]) => {
      const socket = new ClassicPreset.Socket(output.socket.name);
      const out = new ClassicPreset.Output(socket, output.label);
      out.id = output.id;
      node.addOutput(key, out);
    });

    // Reconstruct controls
    Object.entries(controls || {}).forEach(([key, control]) => {
      let ctrl;
      if (control.type === "ProgressControl") {
        ctrl = new ProgressControl(control.value.percent);
      } else {
        console.warn(`Unrecognized control type: ${control.type}`);
        return;
      }

      node.addControl(key, ctrl);
    });

    // Add the reconstructed node to the editor and apply the position
    const task = editor.addNode(node).then(async () => {
      if (position && position.x !== undefined && position.y !== undefined) {
        await area.translate(node.id, { x: position.x, y: position.y });
      }
    });
    addNodeTasks.push(task);
    nodesMap.set(node.id, node);

    // Step 1.5: Update globalStore with globalData for this node
    if (codeData) {
      globalStoreC.updateDataById(id, codeData); // Update or add the node's global data to globalStoreC
    }
  }

  await Promise.all(addNodeTasks);

  // Step 2: Reconstruct Connections
  const addConnectionTasks = [];
  for (const connectionData of data.connections) {
    const sourceNode = nodesMap.get(connectionData.source.node);
    const targetNode = nodesMap.get(connectionData.target.node);

    if (sourceNode && targetNode) {
      const sourceOutput = sourceNode.outputs[connectionData.source.output];
      const targetInput = targetNode.inputs[connectionData.target.input];

      if (sourceOutput && targetInput) {
        const sourceNodeObj = editor.getNode(sourceNode.id);
        const targetNodeObj = editor.getNode(targetNode.id);

        await editor.addConnection(
          new CustomConnection(
            sourceNodeObj,
            connectionData.source.output,
            targetNodeObj,
            connectionData.target.input
          )
        );
      } else {
        console.warn(`Cannot find output or input for connection ${connectionData.id}`);
      }
    } else {
      console.warn(`Cannot find nodes for connection ${connectionData.id}`);
    }
  }

  await Promise.all(addConnectionTasks);

  // Adjust the zoom to fit all nodes
  AreaExtensions.zoomAt(area, editor.getNodes());
}




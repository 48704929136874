import ReactDOM from 'react-dom';
import MyMonacoEditor from '../components/monacoEditor/monacoEditor'; // Import MonacoEditor component

// Function to show the Monaco Editor in a modal dialog
import { createRoot } from 'react-dom/client'; // Import the new createRoot function
import './modal.css'; // Add your modal styles here



/**
 * 
 * 
 * When you're dynamically creating a new DOM node (editorDiv) and rendering the React component inside it using createRoot, the component rendered in that new DOM node is not part of the existing React component tree and does not inherit the DataContext from your main app. This is why useDataContext returns undefined.
 * Solution: Use a React Portal to render the Monaco Editor Popup
 * 
 * @param {*} language 
 * @param {*} id 
 */
export const showEditorPopup = (language, id) => {
  const editorDiv = document.createElement('div');
  document.body.appendChild(editorDiv);

  // Create a root for rendering using React 18's createRoot
  const root = createRoot(editorDiv);

  // Close function for the modal dialog
  const closeEditor = () => {
    root.unmount(); // Unmount the React component
    document.body.removeChild(editorDiv); // Remove the modal div from the DOM
  };

  // Render the Monaco Editor inside a dialog container
  root.render(
    <div className="modal">
      <div className="modal-content">
        <button className="close-button" onClick={closeEditor}>X</button>
          <MyMonacoEditor id={id} language={language} onClose={closeEditor} />
      </div>
    </div>
  );
};




export const showPopup = (editNode, id) => {
    // Create the popup div
    const popupDiv = document.createElement('div');
    popupDiv.className = 'popup-container';
  
    // Add inner HTML for the popup
    popupDiv.innerHTML = `
      <div class="popup-dialog">
        <div class="popup-header">Options</div>
        <ul class="popup-options">
          <li id="edit-node">Edit Node</li>
          <li id="edit-function-code">Edit Function Code</li>
        </ul>
      </div>
    `;
  
    // Append the popup to the body
    document.body.appendChild(popupDiv);
  
    // Add event listeners to the options
    document.getElementById('edit-node').addEventListener('click', () => {
      //alert('Edit Node option selected');
      editNode();
      closePopup();
    });
  
    document.getElementById('edit-function-code').addEventListener('click', () => {
      showEditorPopup("javascript", id);
      closePopup();
    });
  
    // Close popup when clicking outside
    const handleClickOutside = (event) => {
      if (!popupDiv.contains(event.target)) {
        closePopup();
      }
    };
  
    // Function to remove the popup
    const closePopup = () => {
      popupDiv.remove();
      document.removeEventListener('mousedown', handleClickOutside);
    };
  
    // Add listener to detect clicks outside the popup
    document.addEventListener('mousedown', handleClickOutside);
  
    // Dynamically add CSS styles
    const style = document.createElement('style');
    style.innerHTML = `
      .popup-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        padding: 20px;
        width: 250px;
        z-index: 1000;
      }
  
      .popup-dialog {
        display: flex;
        flex-direction: column;
      }
  
      .popup-header {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: center;
      }
  
      .popup-options {
        list-style: none;
        padding: 0;
        margin: 0;
      }
  
      .popup-options li {
        padding: 10px;
        background-color: #f0f0f0;
        margin: 5px 0;
        border-radius: 4px;
        cursor: pointer;
        text-align: center;
        transition: background-color 0.2s;
      }
  
      .popup-options li:hover {
        background-color: #e0e0e0;
      }
    `;
    // Append the style to the head of the document
    document.head.appendChild(style);
  };
  
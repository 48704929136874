// apiService.js
import { ENDPOINTS } from '../../configuration/configuration';

// Fetch data by ID from the API using POST with FormData
export async function fetchDataById(id) {
    try {
        // Construct the URL with the query parameter
        const url = `${ENDPOINTS.FETCH_DATA(id)}?id=${encodeURIComponent(id)}`;

        const response = await fetch(url, {
            method: 'GET', // Use GET for fetching with query parameters
             headers: {
                 'findone': 'true', // Custom header
             }
        });

        if (!response.ok) {
            return null;
        }

        const data = await response.json();
        console.log('Fetched data:', data['data']['extras']);
        return data;
    } catch (error) {
        console.error('Failed to fetch data:', error);
        throw error;
    }
}



// Save (or update) data using POST with FormData
export async function saveData(id, data) {
    try {
        const formData = new FormData();
        
 
        // If updating, append additional update flag
        formData.append('_id', id);
        formData.append('extras', data);
        

        const response = await fetch(ENDPOINTS.SAVE_DATA(id), {
            method: 'POST', // Use POST for saving data
            body: formData,
            headers: {
                'update': 'true', // Custom header
            }
        });

        if (!response.ok) {
            throw new Error('Failed to save data');
        }

        const result = await response.json();
        console.log('Data saved successfully:', result);
        return result;
    } catch (error) {
        console.error('Failed to save data:', error);
        throw error;
    }
}

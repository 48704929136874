import React from 'react';
import { Button, Progress } from 'antd'; // Assuming Ant Design is used for Button and Progress
import { ClassicPreset } from 'rete';
import PropTypes from 'prop-types'; // Import PropTypes for validation

// ButtonControl class
export class ButtonControl extends ClassicPreset.Control {
  constructor(label, onClick) {
    super();
    this.label = label;
    this.onClick = onClick;
  }

  // getData method to convert object to JSON
  getData() {
    return JSON.stringify({
      label: this.label,
      onClick: this.onClick.toString(), // Convert the function to a string representation
    });
  }
}

// ProgressControl class
export class ProgressControl extends ClassicPreset.Control {
  constructor(percent) {
    super();
    this.percent = percent;
  }

  // getData method to convert object to JSON
  getData() {
    return JSON.stringify({
      percent: this.percent,
    });
  }
}

// CustomButton component
export function CustomButton({ data }) {
  // getData method for CustomButton to convert data to JSON
  const getData = () => {
    return JSON.stringify({
      label: data.label,
      onClick: data.onClick.toString(), // Convert the function to a string representation
    });
  };

  return (
    <Button
      onPointerDown={(e) => e.stopPropagation()}
      onDoubleClick={(e) => e.stopPropagation()}
      onClick={data.onClick}
    >
      {data.label}
    </Button>
  );
}

// CustomProgress component
export function CustomProgress({ data }) {
  // getData method for CustomProgress to convert data to JSON
  const getData = () => {
    return JSON.stringify({
      percent: data.percent,
    });
  };

  return <Progress type="circle" percent={data.percent} />;
}

// Define propTypes for CustomButton
CustomButton.propTypes = {
  data: PropTypes.shape({
    onClick: PropTypes.func.isRequired, // Ensure onClick is a function and required
    label: PropTypes.string.isRequired, // Ensure label is a string and required
  }).isRequired, // Ensure data is required
};

// Define propTypes for CustomProgress
CustomProgress.propTypes = {
  data: PropTypes.shape({
    percent: PropTypes.number.isRequired, // Ensure percent is a number and required
  }).isRequired, // Ensure data is required
};

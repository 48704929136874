// configuration.js

//export const API_BASE_URL =  "http://localhost:7071/";
export const API_BASE_URL = "https://nzsappsf.azurewebsites.net/";

// COMPOSITIONS
export const ENDPOINTS = {
    FETCH_DATA: (id) => `${API_BASE_URL}api/CompositionModules/`,
    SAVE_DATA: (id) => `${API_BASE_URL}api/CompositionModules/`,
    // Add more endpoints as needed
};

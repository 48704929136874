import React from "react";
import { createRoot } from "react-dom/client";
import { NodeEditor, ClassicPreset } from "rete";
import { AreaPlugin, AreaExtensions } from "rete-area-plugin";
import { ConnectionPlugin, Presets as ConnectionPresets } from "rete-connection-plugin";
import { ReactPlugin, Presets, ReactArea2D } from "rete-react-plugin";
import { UseMagneticConnection, MagneticConnection } from "./magnetic-connection";
import { CustomNode, CustomConnection } from "./nodes/CustomNodes"; // Importing CustomNode and CustomConnection
import { exportEditorContent } from "./exporting/exportLogic"; // Importing the export logic
import { createTopBar } from "./components/TopBar/TopBar"; // Importing the TopBar logic

import { ButtonControl, ProgressControl, CustomButton, CustomProgress } from './components/CustomControls';

export async function createEditor(container) {
  const editor = new NodeEditor(); // No generic type annotation for `Schemes`
  const area = new AreaPlugin(container); // No generic type annotation for `AreaExtra`
  const connection = new ConnectionPlugin();
  const render = new ReactPlugin({ createRoot });

  /* eslint-disable @typescript-eslint/ban-ts-comment */

  // @ts-ignore
  AreaExtensions.selectableNodes(area, AreaExtensions.selector(), {
    accumulating: AreaExtensions.accumulateOnCtrl(),
  });

  // @ts-ignore
  render.addPreset(
    Presets.classic.setup({
      customize: {
        connection(data) {
          if (data.payload.isMagnetic) return MagneticConnection;
          return Presets.classic.Connection;
        },
      },
    })
  );

  connection.addPreset(ConnectionPresets.classic.setup());

  
  /**
   * This render the components inside the nodes , like buttons circles, progress and so on  and all other views.
   * 
   * The item is added in the CustomNodes.jsx 
   * 
   * THe in the reder here is parsed how to render it
   */
  render.addPreset(
    Presets.classic.setup({
      customize: {
        control(data) {
          if (data.payload instanceof ButtonControl) {
            return CustomButton;
          }
          if (data.payload instanceof ProgressControl) {
            return CustomProgress;
          }
          if (data.payload instanceof ClassicPreset.InputControl) {
            return Presets.classic.Control;
          }
          return null;
        }
      }
    })
  );

  // @ts-ignore
  editor.use(area);
  // @ts-ignore
  area.use(connection);
  // @ts-ignore
  area.use(render);

  // @ts-ignore
  AreaExtensions.simpleNodesOrder(area);

  // @ts-ignore
  UseMagneticConnection(connection, {
    async createConnection(from, to) {
      if (from.side === to.side) return;
      const [source, target] = from.side === "output" ? [from, to] : [to, from];
      const sourceNode = editor.getNode(source.nodeId);
      const targetNode = editor.getNode(target.nodeId);

      await editor.addConnection(
        new CustomConnection(sourceNode, source.key, targetNode, target.key)
      );
    },
    display(from, to) {
      return from.side !== to.side;
    },
    offset(socket, position) {
      const socketRadius = 10;

      return {
        x: position.x + (socket.side === "input" ? -socketRadius : socketRadius),
        y: position.y,
      };
    },
  });

  // Keep track of the selected node
  let selectedNode = null;

  const getSelectedNode = () => {
    return selectedNode;
  };

  const setSelectedNode = (node) => {
    selectedNode = node;
  };

  const { addButton, deleteButton, exportButton, topBar } = await createTopBar(
    editor,
    area,
    container,
    getSelectedNode,
    setSelectedNode
  );

  // Handle node selection using area.nodePointerDown and area.click
  area.addPipe((context) => {
    if (context.type === "nodepicked") {
      selectedNode = context.data;
      deleteButton.style.display = "block"; // Show delete button
    } else if (context.type === "pointerdown") {
      // Check if the target is a button, if so, don't do anything
      if (
        context.data.event &&
        context.data.event.target.tagName.toLowerCase() === "button"
      ) {
        return context;
      }

      selectedNode = null;
      deleteButton.style.display = "none"; // Hide delete button
    }
    return context;
  });

  return {
    destroy: () => {
      area.destroy();
      addButton.remove();
      deleteButton.remove();
      exportButton.remove();
      topBar.remove();
    },
  };
}

import  globalStoreC  from '../../architecture/globalStore'; // Import the context hook
import React, { useState, useEffect } from 'react';
import { Editor } from '@monaco-editor/react';
import './editor.css'; // Your custom CSS
import PropTypes from 'prop-types'; // Import PropTypes

const MyMonacoEditor = ({ id }) => {
  const [language, setLanguage] = useState('javascript');
  const [value, setValue] = useState('');

  // Load initial value based on ID from the global store
  useEffect(() => {
    const existingData = globalStoreC.getDataById(id);
    if (existingData) {
      setValue(existingData.data || getSampleCode(language)); // Load existing data or sample code
    } else {
      setValue(getSampleCode(language)); // Default sample code if no data exists
    }
  }, [id, language]);

  // Sample code for different languages
  const getSampleCode = (lang) => {
    switch (lang) {
      case 'python':
        return '# Sample Python Code\nprint("Hello, World!")';
      case 'html':
        return '<!-- Sample HTML Code -->\n<h1>Hello, World!</h1>';
      case 'css':
        return '/* Sample CSS */\nbody { background-color: blue; }';
      default:
        return '// Sample JavaScript Code\nconst greet = (name) => {\n  console.log("Hello " + name);\n};\n\ngreet("World");';
    }
  };

  // Handle language change
  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    setValue(getSampleCode(newLanguage)); // Reset editor content when changing language
  };

  // Handle file upload
  const handleUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const fileContent = e.target.result;
      setValue(fileContent); // Set the content in the editor
    };
    reader.readAsText(file);
  };

  // Handle content download
  const handleDownload = () => {
    const blob = new Blob([value], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `code.${getFileExtension(language)}`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // Get file extension based on language
  const getFileExtension = (lang) => {
    switch (lang) {
      case 'python':
        return 'py';
      case 'html':
        return 'html';
      case 'css':
        return 'css';
      default:
        return 'js';
    }
  };

  // Handle save (update global store)
  const handleSave = () => {
    globalStoreC.updateDataById(id, { data: value, config: `Language: ${language}` });
    console.log('Data saved:', globalStoreC.dataArray);
  };

  return (
    <div className="editor-wrapper">
      <div className="editor-header">
        <label>Choose Language:</label>
        <select value={language} onChange={handleLanguageChange} className="language-select">
          <option value="javascript">JavaScript</option>
          <option value="python">Python</option>
          <option value="html">HTML</option>
          <option value="css">CSS</option>
        </select>
      </div>

      <div className="editor-container">
        <Editor
          height="50vh"
          language={language}
          value={value}
          theme="vs-dark"
          onChange={(newValue) => setValue(newValue)} // Update the value as the user types
        />
      </div>

      <div className="editor-controls">
        <input
          className="file-input"
          type="file"
          accept=".js,.py,.html,.css"
          onChange={handleUpload}
        />
        <button className="editor-btn" onClick={handleDownload}>
          <i className="fas fa-download"></i> Download
        </button>
        <button className="editor-btn" onClick={handleSave}>
          Save
        </button> {/* Save button will update the object in the global store */}
      </div>
    </div>
  );
};

// Define the expected type for the `id` prop
MyMonacoEditor.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default MyMonacoEditor;

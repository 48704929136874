// globalStore.js
const globalStoreC = {
    dataArray: [],
  
    // Update or add data by ID
    updateDataById(id, newData) {
      const index = this.dataArray.findIndex((item) => item.id === id);
      if (index === -1) {
        // If not found, add a new entry
        this.dataArray.push({ id, ...newData });
      } else {
        // If found, update the existing entry
        this.dataArray[index] = { id, ...newData };
      }
    },
  
    // Get data by ID
    getDataById(id) {
      return this.dataArray.find((item) => item.id === id);
    }
  };
  
  export default globalStoreC; // Ensure you are using a default export
  